@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.content_menu_right_studio {
    width: 25%;
    height: 100%;
    min-width: 450px;
    background-color: #F6F6F6;
    border-radius: 10px 10px 0px 0px;
}

.content_blocklyDiv_studio {
    width: 100%;
}

#blocklyDiv {
    width: calc(100% - 10px) !important;
    /* height: calc(50vh - 46px) !important;*/
}

.injectionDiv {
    width: calc(100%) !important;
    height: 100% !important;
    position: relative;
    overflow: hidden;
    touch-action: none;
    border-radius: 0px 10px 10px 10px;
}

.container_blockly_studio {
    display: flex;
    width: 100%;
}

.container_pageStudio_blocks_menu {
    width: calc(100% - 10px);
    /*     height: 50vh;*/
    margin: 0px 10px;
    border-radius: 10px;
    position: absolute;
    bottom: 0px;
    opacity: 10%;
    transition: 0.3s;
    z-index: 3;
    left: 0px;
}

.container_pageStudio_blocks_menu:hover {
    opacity: 1;
}

#IframePhaser {
    width: 100%;
    /* old 
    height: 45vh;
    */
    /* min-height: 250px; */
    background-color: #D9D9D9 !important;
    /* New  */
    /*
    position: absolute;
    height: 100vh;
    */
    /* box-shadow: none !important; */
    border-radius: 5px;
}

.blocklySvg {
    background-color: #F6F6F6 !important;
    outline: none !important;
    overflow: hidden !important;
    position: absolute !important;
    display: block !important;
    width: 100vw;
    height: calc(55vh - 70px) !important;
    margin-left: 0px !important;
    border-radius: 10px;
}

.blocklyScrollbarHorizontal, .blocklyScrollbarVertical {
    /*  position: inherit !important;*/
    display: none !important;
}

.blocklyToolboxDiv {
    background-color: #00bbf9;
    /* #DDD */
    overflow-x: visible;
    overflow-y: auto;
    padding: 4px 0 4px 0;
    position: absolute;
    z-index: 14;
    -webkit-tap-highlight-color: transparent;
    /* height: 445px !important; */
    width: 150px !important;
    border: none;
    padding-top: 10px;
    height: calc(55vh - 70px) !important;
    border-bottom-left-radius: 10px;
}

.blocklyTreeRoot {
    width: 99px;
    padding: 0px 10px;
    background-color: #00bbf9;
    padding: 10px 0px;
    border-radius: 10px 0px 0px 10px;
}

.blocklyFlyout {
    /* -webkit-order: 2; */
    /* order: 2; */
    /* position: inherit; */
    /* left: 307px; */
    /* top: -606px; */
    /* height: calc(100% - 457px); */
    /* overflow: auto; */
    /* transform: translate(306px, 0px) !important; */
    /* z-index: 99999999; */
    /* width: 250px; */
    /*  */
    height: calc(55vh - 34px) !important;
    -webkit-box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.blocklyMainWorkspaceScrollbar {
    display: none;
}

.blocklyTreeRoot div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 99px;
}

.blocklyToolboxDiv_horizontal ::-webkit-scrollbar {
    border-radius: 0px !important;
    width: 0px !important;
    background-color: transparent !important;
    margin-left: 10px;
    height: calc(100vh - 493px);
    border: none !important;
}

.blocklyToolboxDiv_horizontal:hover ::-webkit-scrollbar {
    width: 6px !important;
}

.blocklyToolboxDiv_horizontal :hover::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: #ccc !important;
}

.blocklyTreeItem {
    width: 99px;
    height: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    transition: 0.8s;
    position: relative;
}

.blocklyTreeItem:hover {
    /*#EF233C; */
    cursor: pointer;
}

.blocklyTreeRow {
    margin: 0px;
    width: 100%;
    height: 100%;
    border-bottom: none !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
}

.blocklyTreeRow:hover span {
    cursor: pointer !important;
}

.blocklyHidden {
    display: none !important;
}

.injectionDiv_horizontal .blocklyTrash {
    transform: translate(157px, 154px);
}

.injectionDiv_horizontal .blocklyZoom {
    transform: translate(164px, 17px);
}

.blocklyZoom, .blocklyTrash {
    transition: 0.3s;
}

.blocklyZoom svg:hover {
    cursor: pointer !important;
}

.blocklyFlyoutBackground {
    fill: #D4CFCF !important;
}

.blocklyBlockCanvas {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #000 !important;
}

.react-draggable.ativo, .react-draggable.ativo .rdTabBefore, .react-draggable.ativo .rdTabAfter {
    background-image: none !important;
    background-color: #ffb703 !important;
    flex: 100;
    min-width: 150px;
}

.react-draggable:hover {
    background-color: #E0E0E0 !important;
}

.react-draggable.ativo:hover, .react-draggable.ativo:hover .rdTabBefore, .react-draggable.ativo:hover .rdTabAfter {
    background-color: #FFA947 !important;
}

.react-draggable.ativo .rdTabCloseIcon {
    opacity: 1 !important;
    color: #FFF !important;
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-draggable.ativo .rdTabCloseIcon:hover {
    background-color: #FFF !important;
    color: #fb8500 !important;
}

.rdTabCloseIcon {
    color: #CCC;
    opacity: 1;
    text-shadow: none !important;
    margin-top: 14px !important;
    font: 24px/1em arial, sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5px;
    padding: 13px;
    display: flex;
    align-items: center;
    transition: 0.3s;
}

.react-draggable .rdTabCloseIcon:hover {
    background-color: #FFF !important;
    color: #fb8500 !important;
}

.rdTabBefore {
    background-image: none;
    box-shadow: none;
    background-color: #EBEBEB;
    height: 45px !important;
}

.rdTabAfter {
    background-image: none !important;
    box-shadow: none !important;
    background-color: #EBEBEB !important;
    width: 36px !important;
    right: -18px !important;
    height: 45px !important;
}

.react-draggable {
    background-image: none !important;
    box-shadow: none !important;
    background-color: #EBEBEB !important;
    height: 45px !important;
    max-width: 153px;
}

.react-draggable:hover span, .react-draggable:hover p {
    background-color: #E0E0E0 !important;
    cursor: pointer !important;
}

.react-draggable span:hover, .react-draggable p:hover {
    cursor: pointer !important;
}

.react-draggable.ativo:hover span, .react-draggable.ativo:hover p {
    background-color: #FFA947 !important;
    /* #EF233C */
    cursor: pointer !important;
}

.react-draggable.ativo span:hover, .react-draggable.ativo p:hover {
    cursor: pointer !important;
}

.rdTabTitle {
    text-shadow: none !important;
    color: #999999;
    text-transform: capitalize;
    height: 45px !important;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0px;
    padding-left: 8px;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    position: initial !important;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: -webkit-box; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
}

.react-draggable.ativo .rdTabTitle {
    color: #FFF !important;
    position: inherit !important;
}

.maisFile {
    display: none !important;
    top: 10px;
    left: 94.0%;
    width: 25px;
    height: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #0066FF;
    text-shadow: none;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    transition: 0.3s;
    /* padding-right: 8px; */
    z-index: 0;
}

.maisFile:hover {
    background-color: #4791FF;
}

#selectToolBox {
    height: 45px;
    background-image: none !important;
    background-color: #00bbf9;
    /* #EF233C; */
    box-shadow: none !important;
    border-radius: 10px 10px 0px 0px;
    transition: 0.3s;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    position: inherit !important;
}

#selectToolBox:hover {
    cursor: pointer;
    /* background-color: #D90429;*/
}

#selectToolBox option {
    background-image: none !important;
    height: 45px !important;
}

#selectToolBox option:hover {
    background-image: none !important;
    background-color: #4791FF !important;
}

.blocklyIconGroup:not(:hover), .blocklyIconGroupReadonly {
    cursor: pointer;
}

.blocklyEditableText, .blocklyNonEditableText>text, .blocklyEditableText>text {
    cursor: text !important;
}

.blocklyText {
    cursor: pointer !important;
}

.rdTabWrapper {
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
}

.rdTabWrapper ul {
    /*
    max-width: none !important;
    min-width: 0px !important;
    padding-left: 0px !important;
    width: calc(100% - 220px);
    overflow-y: hidden;
    overflow-x: auto;
    */
}

.headerLeftStudio2 {
    box-shadow: none !important;
    background-color: #ffb703;
    /* #D90429; */
    border-radius: 10px 0px 0px 0px;
}

.optionHeaderStudio2 {
    width: 50%;
    /* text-align: center; */
    background-color: #ffb703;
    /* #D90429; */
    padding: 0px 20px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px 10px 0px 0px;
}

.addAlgo {
    margin-left: 15px;
    border: none;
    border-radius: 5px;
    background-color: #fb8500;
    /* #EF233C; */
    transition: 0.3s;
}

.addAlgo:hover {
    background-color: #FFA033/*  #FA0F36; */
}

.optionHeaderStudio2.ativo .addAlgo {
    background-color: #FFF;
}

.optionHeaderStudio2.ativo .addAlgo:hover {
    background-color: #F0F0F0;
}

.optionHeaderStudio2 .addAlgoIcon {
    color: #FFF;
    fill: #FFF;
    opacity: 1;
}

.optionHeaderStudio2.ativo .addAlgoIcon {
    color: #00bbf9;
    fill: #00bbf9;
    opacity: 1;
}

.optionHeaderStudio2.ativo {
    background-color: #00bbf9;
    /* #F6F6F6; */
    border-radius: 8px 8px 0px 0px;
}

.optionHeaderStudio2.ativo span {
    color: #FFF;
    /* #070707 */
}

.optionHeaderStudio2 span svg {
    font-size: 30px;
}

.rowTitlebaixo {
    margin: 10px 0px;
    display: none;
}

.rowTitlebaixo div {
    color: #070707;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.__resizable_base__ {
    display: flex;
    align-items: center;
}

.__resizable_base__ .row {
    align-items: center;
    height: 100%;
    width: 100%;
}

.__resizable_base__ span {
    display: none;
}

.group_actions_scenes_fileEdit {
    display: flex;
    align-items: center;
}

.item_scenes_fileEdit {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input_baixo.form-control {
    background-color: transparent !important;
    color: #070707 !important;
    font-size: 17px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    border: none !important;
    font-size: 14px;
    height: 35px;
    padding: 0px 10px;
    transition: 0.3s;
}

.input_baixo.form-control:hover {
    background-color: #E0E0E0 !important;
}

.input_baixo.form-control:focus {
    background-color: #E0E0E0 !important;
}

#divPhaser {
    height: 100%;
}

#phaserTableBaixo {
    height: calc(100% - 50px);
}

.tabBaixoAsset {
    height: calc(100vh - 118px);
    /* height: 432px; */
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 15px;
    background-color: #00bbf9;
    border-radius: 0px;
}

.container_item_fileEdit {
    display: flex;
    align-items: center;
    width: calc(100% - 60px);
    height: 70px;
    background-color: #F6F6F6;
    border: solid 1px #D9D9D9;
    border-radius: 10px;
    margin: 10px 10px;
}

.content_left_item_fileEdit {
    width: 45%;
    display: flex;
    align-items: center;
}

.btn_eye_fileEdit {
    height: 68px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 70px;
    font-size: 25px;
    border: none;
    border-right: solid 1px #D9D9D9;
    color: #AAAAAA;
    transition: 0.3s;
    background-color: transparent;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 9px;
}

.btn_eye_fileEdit:hover {
    cursor: pointer;
    color: #E0E0E0;
}

.input_name_fileEdit {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    border: none;
    margin-left: 10px;
    background-color: transparent;
    height: 50px;
    padding: 10px 10px;
    border-radius: 4px;
    transition: 0.3s;
    width: 75%;
    pointer-events: none !important;
}

.input_name_fileEdit:focus {
    background-color: #D9D9D9;
}

.content_right_item_fileEdit {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.content_group_btn_red_file_edit {
    display: flex;
    align-items: center;
}

.content_group_btn_red_file_edit button {
    border: none;
    width: 50px;
    height: 50px;
    margin: 0px 5px;
    font-size: 18px;
    background-color: #fb8500;
    /*  #EF233C; */
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
}

.content_group_btn_red_file_edit button:hover {
    background-color: #FFA033;
    /*#D90429; */
    color: #F0F0F0;
}

.btn_lock_fileEdit {
    height: 68px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 70px;
    font-size: 25px;
    border: none;
    border-left: solid 1px #D9D9D9;
    color: #AAAAAA;
    margin-top: 7px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.btn_lock_fileEdit:hover {
    cursor: pointer;
    color: #000;
}

.btn_dragglable_item_fileEdit {
    border: none;
    background-color: transparent;
    font-size: 25px;
    color: #FFF;
    /* #AAAAAA; */
    transition: 0.3s;
    padding: 0px;
    height: 70px;
}

.btn_dragglable_item_fileEdit:hover {
    color: #F0F0F0;
    /* #000; */
}

.tabBaixoAsset::-webkit-scrollbar {
    border-radius: 0px !important;
    width: 6px !important;
    background-color: transparent !important;
    margin-left: 10px;
    height: calc(100vh - 493px);
    border: none !important;
}

.tabBaixoAsset:hover::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: #ccc !important;
}

.rdTabBeforeTitle {
    top: 15px !important;
}

.react-draggable.ativo img, .react-draggable.ativo .rdTabBefore img, .react-draggable.ativo .rdTabAfter img, .react-draggable img {
    background-color: #FFF;
    border-radius: 3px;
    height: 17px !important;
    top: 13px !important;
    left: -3px !important;
    z-index: 9999 !important;
}

.optionHeaderAsset {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 35px;
    color: #FFF;
    height: 75px !important;
    margin: 0px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.optionHeaderAsset.ativo {
    color: #CCC !important;
    background-color: #FFF;
}

.lity-content:after {
    box-shadow: none !important;
}

.optionHeaderAsset p {
    font-size: 12px;
    margin: 0px;
    padding: 0px;
}

.optionDivAsset center {
    padding-top: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
}

.btn_menu_phaser {
    border: none !important;
    height: calc(100% - 20px);
    border-radius: 8px;
    margin: 10px;
}

.hamburger-react {
    z-index: 1000;
}

.btn_menu_phaser .hamburger-react {
    color: #fb8500;
}

.content_dropdown_navbar_studio button {
    width: 100%;
    border: none;
    height: 55px;
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    transition: 0.3s;
    padding: 10px;
}

.content_dropdown_navbar_studio button:hover {
    background-color: #F0F0F0;
}

.btn_play_studio {
    position: absolute;
    bottom: 25px;
    right: 25px;
    width: 60px;
    height: 60px;
    font-size: 25px;
    background-color: transparent;
    color: #FFF;
    border: solid 5px transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://csplus.s3.amazonaws.com/engine/prod/icons/icons-jr/StartOnGreenFlag.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.btn_play_studio:hover {
    border: solid 5px #FFA947;
    background-color: #FFA947;
    color: #F0F0F0;
}

@media screen and (max-width: 1760px) {
    .btn_play_studio {
        /*  right: 484px; */
    }
}

.btn_show_menu_in {
    position: absolute;
    right: 460px !important;
    left: unset !important;
    top: 10px !important;
    z-index: 1;
    width: 50px !important;
    height: 50px !important;
    margin: 0px;
    padding: 0px;
    border: none;
    font-size: 20px;
    background: #00bbf9 !important;
    cursor: pointer;
    border-radius: 6px !important;
    color: #FFF;
    transition: 0.3s;
    display: none;
}

.btn_show_menu {
    position: absolute;
    right: 10px !important;
    left: unset !important;
    top: 10px !important;
    z-index: 1;
    width: 50px !important;
    height: 50px !important;
    margin: 0px;
    padding: 0px;
    border: none;
    font-size: 20px;
    background: #00bbf9 !important;
    cursor: pointer;
    border-radius: 6px !important;
    color: #FFF;
    transition: 0.3s;
}

.btn_show_menu:hover, .btn_show_menu_in:hover {
    background-color: #00A8E0 !important;
}

#react-burger-menu-btn {
    display: none;
}

.bm-overlay {
    z-index: 998 !important;
}

.bm-menu-wrap {
    z-index: 0 !important;
}

.bm-burger-bars {
    display: none !important;
}

.content_menu_settings_phaser_TableBaixo {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 10px;
    background-color: #00A8E0;
    border-top: solid 1px #00A8E0;
}

.content_menu_settings_phaser_TableBaixo button:first-child {
    font-size: 35px;
}

.content_menu_settings_phaser_TableBaixo button {
    border: none;
    height: 35px;
    width: 35px;
    margin-left: 10px;
    font-size: 20px;
    /*     background-color: #fb8500; */
    color: #FFF;
    border-radius: 5px;
}

.popup-content {
    border-radius: 5px;
}

.btn_pin_fixed_Phaser {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: calc(55vh - 103px) !important;
    left: 350px;
    z-index: 99;
    border: none;
    padding: 10px;
    border-radius: 6px;
    font-size: 20px;
    background-color: #00A8E0;
    color: #FFF;
}

.btn_pin_fixed_Phaser svg {
    transform: rotate(45deg);
    transition: 0.5s;
}

.btn_pin_fixed_Phaser.active svg {
    transform: rotate(-45deg);
}

.blocklyPath {
    z-index: 99;
}

.btn_fixed_body_blocks, .btn_modal_body_blocks {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 35px;
    height: 35px;
    margin: 2.5px 0px;
    margin-left: 10px;
    border-radius: 6px;
    font-size: 20px;
    background-color: #00A8E0;
    color: #FFF;
}

.btn_fixed_body_blocks svg {
    transform: rotate(45deg);
    transition: 0.5s;
}

.btn_fixed_body_blocks.active svg {
    transform: rotate(-45deg);
}

.blocklyTreeLabel {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0px !important;
}

.text_poup_tippy {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #FFF;
}

.popup-arrow {
    stroke: #fb8500;
    color: #fb8500;
    filter: none !important;
}

.form-check-label {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    color: #000 !important;
    display: none;
}

.principal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

#pageStudio {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-right: 10px;
}

#page-wrap, #outer-container, #root {
    width: 100%;
    height: 100vh;
}

#divIframePhaser {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*
    max-width: 95vw;
    max-height: 95vh;
    position: absolute !important;
    */
}

.indexIframe {
    z-index: 9999999999 !important;
}

.content_move_iframe {
    display: block;
    content: ' ';
    width: 100%;
    height: 100%;
    background-color: red;
    position: absolute;
    border-radius: 5px;
    z-index: 99;
}

.content_iframe_phaserFrame {
    display: flex;
    align-items: center;
    justify-content: center;
}

#divIframePhaser span div:nth-child(2) {
    margin-left: -15px !important;
}

#divIframePhaser span div:nth-child(3) {
    margin-top: -15px !important;
}

#divIframePhaser span div:nth-child(4) {
    margin-left: 5px !important;
}

#divIframePhaser span div:nth-child(6) {
    margin-top: -16px !important;
    margin-left: -16px !important;
}

#divIframePhaser span div:nth-child(7) {
    margin-top: -16px !important;
    margin-left: 5px !important;
}

.btn_7 {
    position: absolute;
    user-select: none;
    width: 10px;
    height: 10px;
    top: 50%;
    right: -5px;
    cursor: ew-resize;
    margin-top: -5px;
    margin-left: 0px;
    left: 100%;
    border-width: 7px;
    border-style: none solid none none;
    border-color: #000;
    border-image: initial;
    box-sizing: border-box;
    z-index: 1;
}

.btn_close_modal_body_blocks {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 8px;
    top: -45px;
    border: none;
    background-color: #fb8500;
    color: #FFF;
    font-size: 30px;
    border-radius: 7px;
}

.window_loading_iframe, .window_loading_iframe2 {
    width: 100%;
    height: calc(100% + 2px);
    position: absolute;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    border-radius: 5px;
    margin-top: 0px;
}

#pageStudio div:nth-child(2) {}

#popover {
    cursor: default;
    display: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: #FFF;
    min-width: 220px;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    z-index: 999999;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    color: #000 !important;
    align-items: center;
    justify-content: center;
}

.popover_fixed {
    position: relative !important;
    cursor: default;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: #FFF;
    min-width: 220px;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 999999;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    color: #000 !important;
    align-items: center;
    justify-content: center;
}

.content_window_IframePhaser_empty {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #00bbf9;
    padding: 5px 10px;
    width: 100%;
}

.content_window_IframePhaser_empty div {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    color: #fff !important;
}

.content_window_IframePhaser_empty div:last-child {
    height: 100%;
    background-color: #FFF;
    border-radius: 5px;
    padding: 5px 10px;
    color: #000;
    cursor: default;
}

.content_window_IframePhaser_empty div:last-child span {
    color: #000;
}

.content_data_iframePhaser {
    margin-right: 10px;
}

.span_name_canvasSize {
    margin: 0px 10px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
}

.alterCanvasSize {
    width: 35px;
    height: 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: 1px;
    border-radius: 0px;
    text-align: center;
    outline: none;
    padding: 5px 10px;
    font-size: 12px;
}

.alterCanvasSize:focus {
    outline: 0;
}

.add_canvasSeize {
    background-color: #5BB85D;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.add_canvasSeize i, .remove_canvasSeize i {
    color: #FFF !important;
}

.remove_canvasSeize {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #D9544D;
}

.value_canvasSize {
    padding: 5px 10px;
    background-color: #D9D9D9;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    border-radius: 5px;
}

.btn_js_menu_settings {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    z-index: 99999 !important;
    position: relative;
}

.modal-backdrop {
    z-index: -1 !important;
}

.title_modal_uploadAsset {
    width: 100%;
    display: flex;
    justify-content: center;
}

.title_modal_uploadAsset h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #000;
    font-size: 20px;
    line-height: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px;
}

.checkSelectImport44 {}

:root {
    --clr-neutral-300: hsl(232, 41%, 97%);
    --clr-neutral-400: hsl(98, 0%, 50%);
    --clr-neutral-900: #020203;
    --clr-accent: #fb8500;
    /* font weight */
    --fw-400: 400;
    --fw-600: 600;
    --fw-700: 700;
    /* Type */
    --ff-primary: "Mulish", sans-serif;
}

/*  min width 768px */

/* reset */

/* general layout */

.flow>*+* {
    margin-top: var(--flow-spacer, var(--spacer));
}

* {
    font-family: inherit;
    line-height: inherit;
    color: inherit;
}

.news {
    background-color: #fff;
    padding: 2.125em 1.25em;
    border-radius: 10px;
    box-shadow: 0px 34px 85px -25px #bbc0ea;
}

.news__title {
    font-size: 1.75rem;
    margin-bottom: 1.5em;
}

.news-grid {
    display: grid;
}

.custom {
    opacity: 0;
    position: relative;
}

.custom+label {
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    column-gap: 1em;
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin-bottom: 0px;
}

.custom[type="checkbox"]+label::before {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background: #F6F6F6;
}

.custom[type="checkbox"]+label::after {
    content: "\2713";
    color: white;
    font-size: 1.25rem;
    position: absolute;
    top: 14px;
    left: 4px;
    transform: scale(0);
    transition: all 300ms ease-in-out;
}

.custom[type="checkbox"]:checked+label::before {
    background-color: var(--clr-accent);
}

.custom[type="checkbox"]:checked+label::after {
    transform: scale(1);
}

.card__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 200px;
    overflow: auto;
}

.card {
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 49%;
    margin-bottom: 2%;
}

.card.active {
    background-color: var(--clr-neutral-300);
}

.card label h5 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 17px;
    white-space: nowrap;
    margin-bottom: 0px;
}

.news__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

input[type="email"] {
    border: 0;
    outline: 0;
    padding: 20px 30px;
    border-radius: 3px;
    border: 1px solid #8f94a1;
}

.news__btn {
    border: none;
    outline: none;
    background: transparent;
    background-color: var(--clr-accent);
    padding: 20px 30px;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
}

@media (min-width: 550px) {
    .news-grid {
        grid-auto-flow: column;
        grid-template-columns: repeat(3, 1fr);
    }
    .news {
        padding: 4.125em 2.25em;
    }
    .news__form {
        flex-direction: row;
        gap: 10px;
    }
    input[type="email"] {
        flex: 0 0 480px;
    }
}

.btn_send_asset_modal {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #FFF;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.btn_send_asset_modal i {
    margin-left: 10px;
    font-size: 19px;
}

.title_label_input_group {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 15px;
    margin-bottom: 0.5rem;
    width: 100%;
}

#inputUploadAsset, #tipoAssetEnviado, #keyNovoAsset {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 15px;
}

.nenhumlog {
    color: #FFF;
    margin: 30px 0px;
}

.label_input_form_config {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 15px;
}

.label_input_form_config::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #CCC;
    font-size: 15px;
}

form {
    margin-top: 0px;
}

.btn_form_config {
    padding: 10px 20px;
}

.Toastify__toast {
    border-radius: 10px;
}

.Toastify__close-button {
    align-self: center;
}

.ace_editor {
    height: calc(90vh) !important;
}

.content_dropdown_navbar_studio_dimensions {}

.content_dropdown_navbar_studio_dimensions .menu-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.menu_item_group_buttons {
    display: flex;
    align-items: center;
}

.title_dropdown_navbar_studio_dimensions {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 15px;
    line-height: 25px;
    margin: 0px;
    padding: 0px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.20) !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
    padding: 0px !important;
    border: none !important;
}

#log {
    min-width: 80vw;
}

.content_infos_dimensions {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.btn_color_1 {
    background-color: #FFBE0B;
}

.btn_color_2 {
    background-color: #FB5607;
}

.btn_color_3 {
    background-color: #FF006E;
}

.btn_color_3 {
    background-color: #8338EC;
}

.btn_color_4 {
    background-color: #3A86FF;
}

#divIframePhaser span {
    display: none;
}

#divIframePhaser:hover span {
    display: flex;
}

#eixo_x, #eixo_y {
    margin-left: 10px;
}

.text_label_input_group {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 15px;
    padding: 0px;
}

.swal2-popup .swal2-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #000;
}

.swal2-content {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}

.swal2-confirm, .swal2-cancel {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

#aceEditorDiv {
    width: 90vw;
    height: 90vh;
}

#selectToolBox {
    pointer-events: none !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 999999 !important;
}

/* VERTICAL */

.blocklyToolboxDiv_vertical {
    height: calc(100vh - 50px)!important;
    width: 298px !important;
    border-bottom-left-radius: 10px !important;
    background-color: #00bbf9 !important;
    overflow-x: hidden !important;
    display: flex;
    align-items: flex-start;
    margin-top: 49px;
    border-radius: 10px;
}

.blocklyToolboxDiv_horizontal {
    height: calc(55vh - 70px) !important;
    width: 99px !important;
    padding: 0px;
}

.blocklyToolboxDiv_horizontal .blocklyTreeRoot {
    padding: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0px;
}

.container_pageStudio_blocks_menu_vertical {
    width: 677px !important;
    height: calc(100vh - 10px) !important;
    bottom: unset !important;
    top: 0px !important;
    left: 5px !important;
}

.container_pageStudio_blocks_menu_horizontal {
    width: calc(100% - 10px) !important;
    left: 0px !important;
    bottom: 0px !important;
}

.blocklySvg_vertical {
    width: 100% !important;
    height: calc(100vh - 10px) !important;
}

.blocklySvg_horizontal {
    width: 100vw !important;
    height: calc(55vh - 70px) !important;
}

.btn_pin_fixed_Phaser_vertical {
    top: 80px !important;
    bottom: unset !important;
}

.btn_pin_fixed_Phaser_horizontal {
    bottom: calc(55vh - 103px) !important;
}

.blocklyFlyout_vertical {
    width: 200px !important;
    height: calc(100vh - 79px) !important;
    /* transform: translate(0px, 374px) !important;*/
    box-shadow: none !important;
    border-right: solid 1px #C0C0C0;
}

.blocklyFlyout_horizontal {
    height: calc(55vh - 70px) !important;
}

.selectToolBox_horizontal {
    width: 99px !important;
    min-width: 99px !important;
}

.rdTabWrapper_vertical {
    height: 100% !important;
    width: 50px !important;
    flex-direction: column !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    margin-top: 22px !important;
}

.rdTabWrapper_vertical::-webkit-scrollbar {
    border-radius: 0px !important;
    width: 5px !important;
    background-color: transparent !important;
    margin-left: 10px;
    height: calc(100vh - 493px);
    border: none !important;
}

.rdTabWrapper_vertical:hover::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: #ccc !important;
}

.content_iframe_Phaser_vertical {
    justify-content: center !important;
    align-items: flex-end !important;
    /* padding-right: 80px !important;*/
}

.injectionDiv_vertical {
    height: calc(100vh - 58px) !important;
    display: flex;
    align-items: center;
}

.myWrapper_vertical {
    justify-content: flex-end !important;
}

.selectToolBox_vertical {
    width: 200px !important;
    display: none !important;
}

.selectToolBox_horizontal {
    width: 154px !important;
}

.content_infos_dimensions_vertical {
    justify-content: flex-end !important;
}

.react-draggable_vertical {}

.react-draggable_vertical .rdTabTitle {
    display: none;
}

.react-draggable_vertical .rdTabBefore, .react-draggable_vertical .react-draggable .rdTabBefore {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
}

.rdTabWrapper_vertical .myTabAfter {
    min-width: 60px !important;
    max-width: 60px !important;
}

.rdTabWrapper_vertical .react-draggable {
    max-width: 50px !important;
    min-width: 50px !important;
}

.rdTabWrapper_vertical span:nth-child(1) {
    /*
    max-width: 37px !important;
    min-width: 37px !important;
    height: 74%;
    top: 8px !important;
    */
}

.rdTabWrapper_vertical span:nth-child(2) {
    /*  display: none !important;*/
}

.rdTabWrapper_vertical span:nth-child(5) {
    max-width: 50px !important;
    min-width: 50px !important;
}

.react-draggable_vertical li .rdTabTitle {
    display: none !important;
}

.react-draggable_vertical .react-draggable.ativo .rdTabCloseIcon {
    max-width: auto !important;
    min-width: auto !important;
}

/*
.myWrapper_vertical2{
    position: absolute;
    top: 382px;
    left: -365px;
    /* width: 50px; */

/* height: 100vh;
    z-index: 9999;
}

.fileListEs_vertical{
    width: 100% !important;
}

*/

.btn_layout_config {
    border: none;
    background-color: #FFBE0B;
    color: #FFF;
    font-size: 20px;
    padding: 10px;
    margin-right: 10px;
    border-radius: 6px;
    transition: 0.3s;
}

.btn_layout_config:hover {
    background-color: #FFA947;
}

button.btn_layout_config.horizontal svg {
    transform: rotate(-90deg);
}

.btn_layout_config.active {
    background-color: #FB5607;
}

.btn_pin_fixed_Phaser_vertical {
    display: none !important;
}

.container_list_files_open {
    width: 49px;
    height: calc(100vh - 79px);
    position: absolute;
    top: 50px;
    left: 290px;
    display: flex;
    align-items: center;
    z-index: 9 !important;
}

.container_item_files_open {
    display: none;
    display: flex !important;
    height: 50px;
    background-color: red;
    margin: 0px 10px;
}

.container_item_files_open.ativo {
    display: flex !important;
}

.item_menu_vertical_tabs {
    padding-left: 10px;
    width: 100%;
    min-height: 87px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #D4CFCF;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 10px;
    margin-top: 0px !important;
    transition: 0.3s;
}

.item_menu_vertical_tabs.ativo {
    background-color: #FFBE0B;
    min-height: 150px !important;
}

.container_icon_menu_vertical {
    width: 100%;
    height: calc(100% - 34px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.btn_close_menu_vertical {
    margin-bottom: 10px;
    border: none;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img_icon_menu_vertical {
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 10px;
    border-radius: 5px;
}

.name_aba_hover_menu_vertical {
    display: none;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-size: 15px;
}

.ativo .name_aba_hover_menu_vertical {
    display: flex !important;
    overflow: hidden;
    text-overflow: clip ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 76px;
    margin: 4px 0px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #000 !important;
}

/*
[aria-posinset="1"] {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000;
    content: "\f074";
    display: block;
    background-image: url("https://lh3.googleusercontent.com/a/default-user=s40-c");
    background-size: cover;
    background-position: center;
    border: solid 2px transparent;
}

[aria-posinset="2"] {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000;
    content: "\f074";
    display: block;
    background-image: url("https://sjc4.discourse-cdn.com/standard14/user_avatar/forum.makecode.com/shakao/45/113_2.png");
    background-size: cover;
    background-position: center;
}

*/

.blocklyTreeItem_vertical div {
    background-color: transparent !important;
}

.myWrapper_horizontal {
    width: calc(100% - 10px);
}

.fileListEs_horizontal {
    min-width: calc(100% - 254px) !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.status_file_input {
    display: none;
}

.icon_toolbox_class {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0px !important;
}

.icon_toolbox_General {
    background-image: url("/assets/icons/general.png");
    background-color: rgb(76, 151, 255);
}

.icon_toolbox_Control {
    background-image: url("/assets/icons/remote-control.png");
    background-color: rgb(255, 171, 25);
}

.icon_toolbox_Events {
    background-image: url("/assets/icons/event.png");
    background-color: rgb(230, 172, 0);
}

.icon_toolbox_Math {
    background-image: url("/assets/icons/calculator.png");
}

.icon_toolbox_Data {
    background-image: url("/assets/icons/data-flow.png");
}

.icon_toolbox_Sensing {
    background-image: url("/assets/icons/incognito.png");
}

.icon_toolbox_Functions {
    background-image: url("/assets/icons/communicate.png");
}

.icon_toolbox_Developer {
    background-image: url("/assets/icons/programmer.png");
}

.icon_toolbox_Particles {
    background-image: url("/assets/icons/particula.png");
}

.icon_toolbox_Audio {
    background-image: url("/assets/icons/audiobook.png");
}

.icon_toolbox_APIs {
    background-image: url("/assets/icons/api.png");
}

.icon_toolbox_Behaviors {
    background-image: url("/assets/icons/orientacao.png");
}

.icon_toolbox_Movement {
    background-image: url("/assets/icons/move.png");
    background-color: rgb(0, 242, 230);
}

.icon_toolbox_Appearance {
    background-image: url("/assets/icons/imagem-corporal.png");
    background-color: rgb(89, 111, 235);
}

.icon_toolbox_Physics {
    background-image: url("/assets/icons/physics.png");
    background-color: rgb(209, 75, 0);
}

.icon_toolbox_Actions {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: .7rem !important;
    background-color: #2d85ff!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_toolbox_Letters {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: .7rem !important;
    background-color: #8b818a!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_toolbox_Input {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: .7rem !important;
    background-color: #9c399c!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_toolbox_Zones {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    font-size: .7rem !important;
    background-color: #61b65a!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_toolbox_Animation {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: .6rem !important;
    background-color: #ff5c00!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_toolbox_Tweens {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: .7rem !important;
    background-color: #e02569!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

[aria-selected="true"] {
    background-color: #00A8E0;
    border-radius: 0px;
}

[aria-selected="true"] .icon_toolbox_class {}

[aria-selected="true"]::before {
    margin-left: -98px;
    content: ' ';
    background-color: #FFBE0B;
    width: 10px;
    height: 50px;
    display: block;
    position: absolute;
    border-radius: 0px 10px 10px 0px;
}

.blocklyTreeIconNone {
    display: none !important;
}

[role="presentation"] {
    display: none !important;
}

.bar_drag_iframe {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0px 0px;
    background-color: #00bbf9;
    width: 100%;
    height: 30px;
}

.bar_drag_iframe span {
    display: flex !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #FFF;
}

.blocklyDropdownMenu {
    margin-top: 10px;
    padding: 10px !important;
    border-radius: 10px 0px 0px 10px;
}

.blocklyDropdownMenu:hover {
    cursor: pointer;
}

.blocklyDropdownMenu .goog-menuitem {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    margin-bottom: 5px;
}

.blocklyDropdownMenu .goog-menuitem-highlight {
    background-color: #CCC !important;
}

.blocklyDropdownMenu .goog-option-selected {
    background-color: #FFBE0B !important;
}

.blocklyDropdownMenu .goog-menuitem .goog-menuitem-content {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blocklyWidgetDiv .goog-option-selected .goog-menuitem-checkbox, .blocklyWidgetDiv .goog-option-selected .goog-menuitem-icon {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    background-image: url('/assets/icons/sprites.png') !important;
    position: static !important;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.blocklyHtmlInput {
    font-size: 17px !important;
}

.activePanZoom {
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    border-radius: 5px;
}

.close_modal_btn {
    position: absolute;
    right: 30px;
    top: 10px;
    z-index: 99;
    border: none;
    background-color: #fb8500;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: #FFF;
    font-size: 20px;
    transition: 0.3s;
}

.close_modal_btn:hover {
    opacity: 0.8;
}

.ace_scrollbar-v {
    width: 6px;
}

.ace_scrollbar-h {
    height: 6px;
}

.content_infos_dimensions_vertical, .content_infos_dimensions_horizontal {
    flex-direction: column;
    align-items: flex-end;
}

#container_eixos_out {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0px;
}

.container_dimensions_canvasSize {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.close_modal_btn.store {
    top: -48px;
    right: 0px;
    width: 40px;
    height: 40px;
}

.form-check-input {
    display: none !important;
}