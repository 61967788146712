.studio_options {
  position: absolute;
  left: calc(10%);
  background-color: #272727;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 1px 6px -4px #000;
  height: 52px;
}

.studio_options .option {
  cursor: pointer;
  height: 60px;
  width: 50px;
  top: -8px;
  /*background-color: #fff;*/
  margin: 0px 2px 0px 2px;
  position: relative;
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  /*box-shadow: 0px 2px 5px -1px #000;*/
}

.hidden_div {
  display: none !important;
}

.studio_options .option img {
  height: 32px;
  margin-top: 15px;
}

.studio_2 {
  height: 55vh;
  width: 100%;
 
}

div#divBlockly {
  width: 100%;
}

.splitter {
  flex: 0 0 auto;
  width: 18px;
  background: url(/media/vsizegrip.png) center center no-repeat #535353;
  min-height: 200px;
  cursor: col-resize;
}

.blocklyScrollbarHandle {
  fill: #353c42;
}

.blocklyMainBackground {
  stroke: #c6c6c600;
}

.blocklyToolboxDiv {
  background-color: #282f34;
}

.blocklyFlyoutBackground {
  fill: #22262c;
  fill-opacity: 1;
}

.blocklyTreeLabel {
  color: #fff;
}

.blocklyTreeRow:not(.blocklyTreeSelected):hover {
  background-color: #414a50;
}

.headerLeftStudio {
  display: flex;
}

.optionHeaderStudio {
  width: 50%;
  text-align: center;
  background-color: #3b4146;
  padding: 5px;
  cursor: pointer;
}

.btfontsize {
  background-color: transparent;
  color: #fff;
  border: navajowhite;
  padding: 0;
}

.btfontsize:focus {
  outline: none;
}

.optionHeaderStudio .fontsize {
  display: none;
}

.optionHeaderStudio.ativo .fontsize {
  display: block !important;
}

.headerLeftStudio .ativo {
  background-color: #282f34;
}

.optionHeaderStudio font {
  color: #fff;
  font-weight: 600;
}

.optionHeaderStudio.ativo {
  background-color: #282f34;
}

.optionHeaderAsset {
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}

.optionHeaderAsset .fa-folder-open {
  display: none;
}

.optionHeaderAsset.ativo .fa-folder-open {
  display: initial !important;
  margin-right: 3px;
}

.optionHeaderAsset.ativo .fa-folder {
  display: none !important;
}

#divIframePhaser {
   margin-top: 10px !important;
}

.headerLeftStudio2 {
  display: flex;
  box-shadow: 0px 7px 12px -11px #000;
}

.optionHeaderStudio2 {
  width: 50%;
  text-align: center;
  background-color: #3b4146;
  padding: 0px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
}

.headerLeftStudio2 .ativo {
  background-color: #282f34;
}

.optionHeaderStudio2 span {
  color: #FFF;
  font-weight: 600;
}

.optionHeaderStudio2.ativo {
  background-color: #4b5054;
}

.optionHeaderStudio2.ativo span{
  color: #000 ;
}



.rc-tooltip.rc-tooltip-zoom-enter, .rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter, .rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}

.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active, .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
  pointer-events: none;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top, .rc-tooltip-placement-topLeft, .rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}

.rc-tooltip-placement-right, .rc-tooltip-placement-rightTop, .rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom, .rc-tooltip-placement-bottomLeft, .rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}

.rc-tooltip-placement-left, .rc-tooltip-placement-leftTop, .rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow, .rc-tooltip-placement-rightTop .rc-tooltip-arrow, .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow, .rc-tooltip-placement-leftTop .rc-tooltip-arrow, .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.tableAsset li {
  list-style-type: none;
}

#phaserTableBaixo {}

#phaserTableBaixo th {
  color: #fff;
  font-size: 12px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  border: 0px solid #000;
}

textarea.form-control:focus, input.form-control:focus, input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, [type=text].form-control:focus, [type=password].form-control:focus, [type=email].form-control:focus, [type=tel].form-control:focus, [contenteditable].form-control:focus {
  box-shadow: 0px 0px 0px 0px #ddd;
}

.input_baixo.form-control {
  background-color: #282f34 !important;
  color: #ffffff !important;
  border: none !important;
  font-size: 14px;
  font-weight: 300;
}

.tobd_n i {
  color: #fff;
}

.input_baixo {
  height: 20px;
  padding-left: 0px;
  border-radius: 0px;
}

.ckbx-style-14 label {
  height: 20px !important;
  background: #3330 !important;
  width: 80px;
}

.ckbx-style-14 label:after {
  font-size: 10px !important;
  width: 40% !important;
}

.ckbx-style-14 label:before {
  font-size: 10px !important;
  width: 40% !important;
  left: 40% !important;
}

.ckbx-style-14 {
  padding: 0px 0px 0px 0px !important;
  margin-top: 0px !important;
  width: 0px !important;
}

.tobd_n {
  border: 0px solid #000 !important;
  border-collapse: separate !important;
  border-spacing: 4px !important;
}

.tobd_n td, .tobd_n th {
  border-top: 1px solid #3b4146 !important;
  border-bottom: 1px solid #3b4146 !important;
}

.tobd_n td {
  padding: 3px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.bt_tab {
  height: 20px;
  padding: 0px;
}

.rowTitlebaixo {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}


.selectTipoEditor {
  width: 100%;
  height: 20px;
  padding: 0px;
  border: none;
  padding-left: 0px;
  background-color: #282f34;
  color: #fff;
}

.optionDivAsset {
  display: none;
}

.optionDivAsset.ativo {
  display: block !important;
}
