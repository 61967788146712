.customConfigAcer{

    position: absolute;
    z-index: 9999;
    background-color: #000;
    top: calc(100% - 20px);
    left: calc(100% - 190px);
    padding: 0px 5px 0px 5px;
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px -1px 6px -3px #000; 

}

.sizeFontAce{

	color:#fff;
	font-weight: 600;

}

.iconSizeFontAce{

	color:#fff;

}

.selectThemeAce{

	padding: 0px;
	height: 20px;
	border: none;
	margin-left: 10px;
	padding-right:5px; 
	padding-left:5px;
	background-color: #ffffff00;
	color: #fff;
	
}