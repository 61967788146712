@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#log{

	font-size: 12px;

}

#log img{ height: 23px !important; }


.nenhumlog{ 
    text-align: center; 
    font-size: 16px; 
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
}

.logwarn{

    background-color: rgb(224, 177, 76) !important;
    color: #f7f7f7 !important;

}

.logerror{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    background-color: rgb(193, 44, 11) !important;

}

.logdiv{ margin-top: 5px !important; margin-bottom: 0px; }
